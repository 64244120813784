import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import Navigationbar from "./components/Navigationbar";

import "./App.css";
function App() {
  return (
    <BrowserRouter>
      <Navigationbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

/*
      <Footer />

"homepage": "http://intrain.app/thisissimplylovely",
basename="thisissimplylovely/"
*/