//import { NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useRef } from "react";
import "./Navigationbar.css";
import logo from "../imgs/Group 7@2x.png";
import { NavHashLink as NavLink } from "react-router-hash-link";

function Navigationbar() {
  const navRef = useRef();

  const ShowNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const scrollWithOffset = (el, offset) => {
    window.scroll({
      top: el.offsetTop - offset,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="navigation-cover">
      <div className="navigation-container">
        <div className="navigation">
          <img className="logo_imgs" src={logo} alt="Profile"></img>
        </div>
        <div className="navigation">
          <nav ref={navRef}>
            <NavLink
              className="nav-bar-link"
              to="/#top"
              onClick={ShowNavbar}
              smooth={true}
              scroll={(el) => scrollWithOffset(el, 75)}
            >
              home
            </NavLink>
            <NavLink
              className="nav-bar-link"
              to="/#services"
              onClick={ShowNavbar}
              smooth={true}
              scroll={(el) => scrollWithOffset(el, 75)}
            >
              services
            </NavLink>
            <NavLink
              className="nav-bar-link"
              to="/#portfolio"
              onClick={ShowNavbar}
              smooth={true}
              scroll={(el) => scrollWithOffset(el, 75)}
            >
              portfolio
            </NavLink>
            <NavLink
              className="nav-bar-link"
              to="/#myclients"
              onClick={ShowNavbar}
              smooth={true}
              scroll={(el) => scrollWithOffset(el, 75)}
            >
              my clients
            </NavLink>
            <NavLink
              className="nav-bar-link"
              to="/#contact"
              onClick={ShowNavbar}
              smooth={true}
              scroll={(el) => scrollWithOffset(el, 75)}
            >
              contact
            </NavLink>
            <button className="nav-btn nav-close-btn" onClick={ShowNavbar}>
              <FaTimes />
            </button>
          </nav>
          <button className="nav-btn" onClick={ShowNavbar}>
            <FaBars />
          </button>
        </div>
      </div>
    </div>
  );
}
export default Navigationbar;
