import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import './fonts/Helvetica_LT_Bold.ttf';
import './fonts/Helvetica_LT_Regular.ttf';
import './fonts/HelveticaNeueLTCom-Bd.ttf';
import './fonts/HelveticaNeueLTCom-Lt.ttf';


const loadingMarkup = (
  <div className="text-center">
    <h3>Loading..</h3>
  </div>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>
);
