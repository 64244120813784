import "./Home.css";
import profile_photo from "../imgs/Bogi_profile_v1@2x.png";

import img1 from "../imgs/3D_img1.png";
import img2 from "../imgs/3D_img2.png";
import img3 from "../imgs/3D_img3.png";
import { Link } from "react-router-dom";
import Imagelist from "./Imagelist";
import { NavHashLink as NavLink } from "react-router-hash-link";

const images = [
  {
    filename: "4_ABCD_bach_21072021@2x.png",
    alt_message: "portfolio item",
    details: "portfolio example 1",
  },
  {
    filename: "8_3OG_21072021@2x.png",
    alt_message: "portfolio item",
    details: "portfolio example 2",
  },
  {
    filename: "Dachgeschoss_20210222_4K@2x.png",
    alt_message: "portfolio item",
    details: "portfolio example 3",
  },
  {
    filename: "KH_front05_neu_h@2x.png",
    alt_message: "portfolio item",
    details: "portfolio example 4",
  },
  {
    filename: "lobby_neu_010_people_1_b@2x.png",
    alt_message: "portfolio item",
    details: "portfolio example 5",
  },
  {
    filename: "neulengbach3_plus@2x.png",
    alt_message: "portfolio item",
    details: "portfolio example 6",
  },
  {
    filename: "PP_b_NEU_28092020_parkplatz_2@2x.png",
    alt_message: "portfolio item",
    details: "portfolio example 7",
  },
  {
    filename: "SM_R_lobby_30082021@2x.png",
    alt_message: "portfolio item",
    details: "portfolio example 8",
  },
  {
    filename: "WBB_Spital am Pyhrn_01_07102021@2x.png",
    alt_message: "portfolio item",
    details: "portfolio example 9",
  },
  {
    filename: "Westansicht_20210222_4K@2x.png",
    alt_message: "portfolio item",
    details: "portfolio example 10",
  },
  {
    filename: "Zimmer204_20210307_A@2x.png",
    alt_message: "portfolio item",
    details: "portfolio example 11",
  },
  {
    filename: "Zimmer204_20210307_B2@2x.png",
    alt_message: "portfolio item",
    details: "portfolio example 12",
  },
];

function Home() {
  const scrollWithOffset = (el, offset) => {
    window.scroll({
      top: el.offsetTop - offset,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="block_cover">
      <div className="section_container" id="top">
        <div className="mainblock-container">
          <div className="mainblock-left">
            <span className="title">Hello.</span>
            <span className="title">I am Bogi.</span>
            <span className="subtitle">
              I am an architect and I visualize buildings in 3D.
            </span>
            <div className="button-container">
              <NavLink
                to="/#contact"
                smooth={true}
                scroll={(el) => scrollWithOffset(el, 75)}
              >
                <button className="my_buttons btn_left">hire me</button>
              </NavLink>
              <NavLink
                to="/#portfolio"
                smooth={true}
                scroll={(el) => scrollWithOffset(el, 75)}
              >
                <button className="my_buttons btn_right">my works</button>
              </NavLink>
            </div>
          </div>
          <div className="mainblock-right">
            <img
              className="rounded_imgs"
              src={profile_photo}
              alt="Profile"
            ></img>
          </div>
        </div>
        <div className="mainblock-container">
          <div className="mainblock-left">
            <span className="description">
              I can create 3D models using ArchiCAD. Then I put in virtual
              materials, lights and objects into the model. Once these are done,
              I create renderings. With some final post processing touches I
              make the scene look alive.
            </span>
          </div>
          <div className="mainblock-right">
            <span className="description">
              I can create 3D models using ArchiCAD. Then I put in virtual
              materials, lights and objects into the model. Once these are done,
              I create renderings. With some final post processing touches I
              make the scene look alive.
            </span>
          </div>
        </div>
      </div>

      <div className="section_container" id="services">
        <span className="section_title">what I do</span>
        <div className="mainblock-container">
          <div className="mainblock">
            <img className="rounded_3d_imgs" src={img1} alt="3D modeling"></img>
            <span className="work-title">3D modeling</span>
          </div>
          <div className="mainblock">
            <img
              className="rounded_3d_imgs"
              src={img2}
              alt="3D rendering"
            ></img>
            <span className="work-title">3D rendering</span>
          </div>
          <div className="mainblock">
            <img className="rounded_3d_imgs" src={img3} alt="composits"></img>
            <span className="work-title">composits</span>
          </div>
        </div>
      </div>

      <div className="section_container" id="portfolio">
        <span className="section_title">my works</span>
        <div className="mainblock-container container_wrapping">
          <Imagelist images={images} />
        </div>
      </div>

      <div className="section_container" id="myclients">
        <div className="mainblock-container container_wrapping">
          <h1>These are my clients</h1>
        </div>
      </div>

      <div className="section_container" id="contact">
        <div className="mainblock-container container_wrapping">
          <h1>Contact</h1>
        </div>
      </div>
    </div>
  );
}
export default Home;
