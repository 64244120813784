import React from "react";
import "./Home.css";

const Image = (props) => {
  const name = props.name;
  const alt = props.alt;
  const details = props.details;
  const key = props.key;

  try {
    const image = require(`../imgs/${name}`);

    if (!image) return null;
    return (
      <a target="_blank" href={image}>
        <img className="img_thumbnails" src={image} alt={alt} />
      </a>
    );
  } catch (error) {
    console.log(`Image with name "${name}" does not exist`);
    return null;
  }
};

export default Image;
