import React from "react";
import Image from "./Image";
import "./Home.css";

const Imagelist = ({ images }) => {
  try {
    const listItems = images.map((listitem, i) => (
      <div className="mainblock tiled_images">
        <Image
          name={listitem.filename}
          alt={listitem.alt_message}
          details={listitem.details}
          key={i}
        ></Image>
      </div>
    ));
    return listItems;
  } catch (error) {
    console.log(`error`);
    return null;
  }
};

export default Imagelist;
